import Speech from 'speak-tts';

// eslint-disable-next-line import/prefer-default-export
export function speak(text) {
  const speech = new Speech();

  return speech.init({
    lang: 'zh-CN',
  }).then(
    () => speech.speak({ text }),
  );
}
