import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Async as Select } from 'react-select';
import { flow, memoize, sortBy } from 'lodash/fp';
import { setHighlighted } from '../redux/action-creators';
import { pinyinify } from '../lib/pinyinify';
import MediaQuery from 'react-responsive';

const propTypes = {
  highlighted: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  items: PropTypes.arrayOf(PropTypes.object),
  onHighlight: PropTypes.func.isRequired,
};

const defaultProps = {
  highlighted: undefined,
  items: [],
};

const loadOptions = memoize(options => (inputValue) => {
  if (inputValue.length < 2) {
    return Promise.resolve([]);
  }

  return Promise.resolve(options.filter(
    opt => opt.properties.mandarinAscii.toLowerCase().startsWith(inputValue.toLowerCase()),
  ));
});

function Search({ highlighted, items, onHighlight }) {

  let customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted black',
      color: state.isSelected ? 'black' : 'black',
      padding: 20,
      background: 'black',
      color: '#fff',
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: '100%',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
    placeholder: (provided, state) => ({
      ...provided,
      color: '#fff',
      fontSize: '30px',
      fontWeight: 'normal',
    }),
    input: (provided, state) => ({
      ...provided,
      color: '#fff',
      fontSize: '30px',
      fontWeight: 'normal',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#fff',
      fontSize: '30px',
      fontWeight: 'normal',
      overflow: 'visible',
    }),
    menu: (provided, state) => ({
      ...provided,
      background: 'black',
      color: '#fff',
      padding: 0,
      border: 'none',
    }),
    container: (provided, state) => ({
      ...provided,
      color: '#fff',
      fontSize: '20px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: 0
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      display: 'none'
    }),
  }

  let mobileCustomStyles = {
    ... customStyles,
    placeholder: (provided, state) => ({
      ...provided,
      color: '#fff',
      fontSize: '20px',
      fontWeight: 'normal',
    }),
    input: (provided, state) => ({
      ...provided,
      color: '#fff',
      fontSize: '20px',
      fontWeight: 'normal',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#fff',
      fontSize: '20px',
      fontWeight: 'normal',
      overflow: 'visible',
    })
  }

  return (
    <MediaQuery query="(max-device-width: 1224px)">
        {matches => (
          <div id="sidebar">
            <div className="heading">
              <h1>汉字<br/>星图</h1>
              <h2><span>HANZI</span><br/>UNIVERSE</h2>
            </div>
            <Select
              styles={matches ? mobileCustomStyles : customStyles}
              className='search-box'
              value={highlighted}
              onChange={onHighlight}
              loadOptions={loadOptions(items)}
              getOptionLabel={opt => `${opt.properties.character} ${pinyinify(opt.properties.mandarinAscii+opt.properties.mandarinTone)}`}
              getOptionValue={opt => opt.properties.character}
              components={{
                DropdownIndicator: null,
              }}
              placeholder='Search'
              noOptionsMessage={()=>''}
            />
            <p>8,615 Chinese characters (hàn zì) mapped according to their <strong><a href="http://www.cns.nyu.edu/~lcv/ssim/" title="Structural Similarity Index">visual similarity</a></strong>.</p>
            <p>Search for a character by <em><a href="https://en.wikipedia.org/wiki/Pinyin" title="Hanyu Pinyin">pinyin</a></em> spelling or explore the map by zooming into <strong><a href="https://gephi.org/features/" title="Gephi as clustering tool">clusters of similar looking characters</a></strong>. Once a character is selected, <strong>similar sounding characters</strong> are also highlighted to demonstrate the complexity of the Chinese writing system.</p>
            <p>Characters are coloured by how they are pronounced in Mandarin which can be one of the <strong><a href="https://en.wikipedia.org/wiki/Standard_Chinese_phonology#Tones" title="Standard Chinese phonology">five tones</a></strong>: <span className="zero">0</span> <span className="one">1</span> <span className="two">2</span> <span className="three">3</span> <span className="four">4</span></p>
            <p>Select a character and click the <i className="fas fa-headphones-alt"></i> icon to hear the pronunciation.</p>
          </div>
      )}
    </MediaQuery>
  );
}

Search.propTypes = propTypes;
Search.defaultProps = defaultProps;

const processItems = memoize(flow(
  sortBy('properties.strokes'),
  sortBy('properties.mandarinAscii'),
));

function mapStateToProps(state) {
  return {
    items: processItems(state.data.features),
    highlighted: state.highlighted,
  };
}

const mapDispatchToProps = {
  onHighlight: setHighlighted,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
