import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux';
import { store } from './redux/store'
import Map from './components/map'
import Search from './components/search'
import logo from './logo.svg'

class Application extends React.Component {
  componentDidMount() {
    document.getElementById("loading").style.opacity = 0;
  }
  render() {
    return (
      <Provider store={store}>
        <div id="main">
          <Map />
          <Search />
          <footer>
            <a href="https://smallmultiples.com.au">
              <img src={logo} alt="Small Multiples Logo"></img>
            </a>
            <span>
              <a href="https://smallmultiples.com.au/projects/hanzi-universe/" title="About the project on Small Multiples website">Lean more about this project </a>| Created by Jack Zhao | Contributors: David Walsh, Harry Morris, Joachim Bachstätter, Mike Riethmuller
            </span>
          </footer>
        </div>
      </Provider>
    );
  }
}

ReactDOM.render(<Application />, document.getElementById('app'));
