import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash/fp';
import ReactMapGL, { Popup, NavigationControl, FlyToInterpolator } from 'react-map-gl';
import Glyph from './glyph';
import { setHighlighted } from '../redux/action-creators';
import MediaQuery from 'react-responsive';

const MAPBOX_API_ACCESS_TOKEN = 'pk.eyJ1Ijoic21hbGxtdWx0aXBsZXMiLCJhIjoiRk4xSUp6OCJ9.GilBdBaV0oKMZgBwBqRMWA';
const LON_MIN = 56.25;
const LAT_MIN = 2;
const LON_MAX = 151.17;
const LAT_MAX = 60;

class Map extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    highlighted: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    onHighlight: PropTypes.func.isRequired,
  };

  static defaultProps = {
    highlighted: undefined,
  };

  state = {
    viewport: {
      latitude: 37.7375,
      longitude: 105.6036,
      height: '100%',
      width: '100%',
      zoom: 4,
    },
  };

  mapRef = React.createRef();

  getRandom = (min, max) => {
      return Math.random() * (max - min) + min;
  }

  componentDidUpdate(prevProps) {
    const { highlighted } = this.props;

    if (highlighted && !isEqual(prevProps.highlighted, highlighted)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(state => ({
        viewport: {
          ...state.viewport,
          latitude: highlighted.geometry.coordinates[1],
          longitude: highlighted.geometry.coordinates[0]
        },
      }));
    }
  }

  handleClick = (event) => {
    const { onHighlight } = this.props;
    const map = this.mapRef.current;

    const features = map.queryRenderedFeatures(event.point);

    onHighlight(features[0]);
  }

  handlePopupClose = () => {
    const { onHighlight } = this.props;

    onHighlight(undefined);
  };

  handleViewportChange = (viewport) => {
    this.setState({
      viewport,
    });
  };

  getPaintColour = () => {
    const colours = ['#E9C481', '#FCF68B','#B7D8E0']
    return colours[Math.floor(Math.random()*colours.length)];
  }

  render() {
    const { data, highlighted } = this.props;
    const { viewport } = this.state;
    const mapStyle = {
            version: 8,
            glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
            sources: {
              characters: {
                type: 'geojson',
                data,
              },
            },
            layers: [
              {
                id: 'bg',
                type: 'background',
                paint: {
                  'background-color': '#151116',
                },
              },
              {
                id: 'characters',
                type: 'symbol',
                source: 'characters',
                // "source-layer": "original",
                layout: {
                  'icon-image': 'monument-15',
                  'text-field': '{character}',
                  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                  'text-size': {
                    stops: [
                      [4, 7],
                      [7, 18],
                      [13, 22],
                      [18, 34],
                      [22, 42],
                    ],
                  },
                  // "text-offset": [0, 0.6],
                  'text-anchor': 'top',
                  'text-allow-overlap': true,
                  'text-ignore-placement': true
                },
                paint: {
                  'text-color': [
                    'match',
                    ['get', 'mandarinTone'],
                     0, '#E1E1CB',
                     1, '#E1E1CB',
                     2, '#E1E1CB',
                     3, '#E1E1CB',
                     4, '#E1E1CB',
                     /* other */ '#E1E1CB'
                  ],
                  'text-opacity': [
                    'match',
                    ['get', 'strokes'],
                    28, 0.2,
                    27, 0.2,
                    26, 0.2,
                    25, 0.2,
                    24, 0.2,
                    23, 0.2,
                    22, 0.2,
                    21, 0.2,
                    20, 0.2,
                    19, 0.2,
                    18, 0.5,
                    17, 0.5,
                    16, 0.5,
                    15, 0.5,
                    14, 0.5,
                    13, 0.5,
                    12, 0.5,
                    11, 0.5,
                    10, 0.5,
                    9, 0.5,
                    8, 1,
                    7, 1,
                    6, 1,
                    5, 1,
                    4, 1,
                    3, 1,
                    2, 1,
                    1, 1,
                    /* other */ 1
                  ],
                  'text-halo-width': 1,
                  'text-halo-blur': 1.6,
                  'text-halo-color': [
                    'match',
                    ['get', 'mandarinTone'],
                     0, '#EA0C01',
                     1, '#CADBE1',
                     2, '#FDF4F9',
                     3, '#E7BF9E',
                     4, '#fecc5c',
                     /* other */ '#fff'
                  ]
                },
                filter: ['!in', 'mandarinAscii', highlighted ? highlighted.properties.mandarinAscii : ''],
              },
              {
                id: 'characters-highlighted',
                type: 'symbol',
                source: 'characters',
                // "source-layer": "original",
                layout: {
                  'text-field': '{character}',
                  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                  'text-size': {
                    stops: [
                      [4, 20],
                      [7, 20],
                      [13, 30],
                      [18, 30],
                      [22, 40],
                    ],
                  },
                  // "text-offset": [0, 0.6],
                  'text-anchor': 'top',
                  'text-allow-overlap': true,
                  'text-ignore-placement': true,
                },
                paint: {
                  'text-color': [
                    'match',
                    ['get', 'mandarinTone'],
                     0, '#000',
                     1, '#000',
                     2, '#000',
                     3, '#000',
                     4, '#000',
                     /* other */ '#000'
                  ],
                  'text-halo-color': [
                    'match',
                    ['get', 'mandarinTone'],
                     0, '#EA0C01',
                     1, '#CADBE1',
                     2, '#FDF4F9',
                     3, '#E7BF9E',
                     4, '#fecc5c',
                     /* other */ '#fff'
                  ],
                  'text-halo-width': 3,
                  'text-halo-blur': 0,
                  'text-opacity': 1
                },
                filter: ['in', 'mandarinAscii', highlighted ? highlighted.properties.mandarinAscii : ''],
              },
            ],
          }
    return (
      <MediaQuery maxWidth={767}>
        {matches => (
          <div id="map" className="absolute top right left bottom">
            <ReactMapGL
              {...viewport}

              ref={this.mapRef}
              mapboxApiAccessToken={MAPBOX_API_ACCESS_TOKEN}
              mapStyle={mapStyle}
              maxBounds={[
                [LON_MIN, LAT_MIN],
                [LON_MAX, LAT_MAX],
              ]}
              onClick={this.handleClick}
              onViewportChange={this.handleViewportChange}
              minZoom={matches ? 1 : 4}
              scrollZoom={matches ? false : true}
              transitionDuration={200}
              transitionInterpolator={new FlyToInterpolator()}
              attributionControl={false}
            >
              {highlighted && (
                <Popup
                  key={highlighted.properties.character}
                  closeOnClick={false}
                  latitude={highlighted.geometry.coordinates[1]}
                  longitude={highlighted.geometry.coordinates[0]}
                  onClose={this.handlePopupClose}
                >
                  <Glyph {...highlighted.properties} />
                </Popup>
              )}
              <div style={{position: 'absolute', right: 20, top: 20}}>
                <NavigationControl
                  onViewportChange={this.handleViewportChange}
                />
              </div>
            </ReactMapGL>
          </div>
        )}
      </MediaQuery>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.data,
    highlighted: state.highlighted,
  };
}

const mapDispatchToProps = {
  onHighlight: setHighlighted,
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
