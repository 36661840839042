import React from 'react';
import PropTypes from 'prop-types';
import { pinyinify } from '../lib/pinyinify';
import { speak } from '../lib/speech';
import { connect } from 'react-redux'

var googleTranslate = require('google-translate')('AIzaSyC7VoF8netazYra02IzIORW18f9ThKBIR8');


let Glyph = class Glyph extends React.Component {

  static propTypes = {
    // cantoneseAscii: PropTypes.string.isRequired,
    // cantoneseTone: PropTypes.number.isRequired,
    character: PropTypes.string.isRequired,
    mandarinAscii: PropTypes.string.isRequired,
    mandarinTone: PropTypes.number.isRequired,
    strokes: PropTypes.number.isRequired,
  };

  state = {
    definition: '[Translating...]',
  };

  componentDidMount() {
    const self = this;
    googleTranslate.translate(this.props.character, 'zh-CN', 'en', (err, data) => self.setState({
      definition: data.translatedText
    }));
  }

  render() {
    const { character, mandarinAscii, mandarinTone, strokes } = this.props;
    const pronunciation = pinyinify(mandarinAscii + mandarinTone) + ' (tone' + mandarinTone + ')';
    // console.log(translateCharacter(character));

    if (this.state.definition.toString().match(/[\u3400-\u9FBF]/)) {
      this.setState({
        definition: ''
      })
    }

    return (
      <div>
        <h4>{character}</h4>
        <p className="definition">{this.state.definition}</p>
        <div className="row">
          <span>Pronunciation</span><br/>
          {pronunciation}
          {' '}
          <button
            type="button"
            onClick={() => speak(character)}
          >
            <i className="fas fa-headphones-alt"></i>
          </button>
        </div>
        <div className="row">
          <span>Strokes</span><br/>
          {strokes}
        </div>
      </div>
    );
  }
}

export default Glyph;
